
import { Component, Emit, Prop } from 'vue-property-decorator';
import { OrderFlavor } from '@/enums/OrderFlovor';
import { VenueLabel } from '@/interfaces/models/VenueLabel';
import { Venue } from '@/interfaces/models/Venue';
import CustomerGroup from '@/mixins/CustomerGroup';
import { mixins } from 'vue-class-component';
import { SelectItem } from '@/interfaces/components/SelectItem';
import VRangePicker from '@/components/shared/form/VRangePicker.vue';
import Countries from '@/mixins/Countries';

@Component({
  components: { VRangePicker },
})
export default class ReportingFilter extends mixins(CustomerGroup, Countries) {
  @Prop({ type: Array, default: () => [] }) public labels!: VenueLabel[];
  @Prop({ type: Array, default: () => [] }) public venues!: Venue[];

  public selectedFlavor: OrderFlavor = OrderFlavor.PROD_RELEASE;
  public selectedLabel: string | null = null;
  public selectedTypes: string[] = [];
  public selectedPeriodTypes: string[] = [];
  public selectedCustomerGroup: CustomerGroup | '' = '';
  public selectedVenues: string[] = [];
  public selectedCountry: string = '';
  public range: { from: string; to: string } | null = null;

  get selection() {
    return {
      flavor: this.selectedFlavor,
      label: this.selectedLabel,
      venues: this.selectedVenues,
      customerGroup: this.selectedCustomerGroup,
      country: this.selectedCountry,
      exports: [...this.selectedTypes, ...this.selectedPeriodTypes],
      ...this.range,
    };
  }

  get scopedCustomerGroups() {
    if (this.$isAdmin()) {
      return this.customerGroups;
    }
    if (this.$hasCustomerGroup()) {
      return this.customerGroups.filter((i: SelectItem) => i.value === '' || i.value === this.$getCustomerGroup());
    }

    return [];
  }

  get periodTypes() {
    return [
      {
        value: 'daily',
        text: this.$t('reporting.periods.daily'),
      },
      {
        value: 'weekly',
        text: this.$t('reporting.periods.weekly'),
      },
      {
        value: 'monthly',
        text: this.$t('reporting.periods.monthly'),
      },
      {
        value: 'total',
        text: this.$t('reporting.periods.total'),
      },
    ];
  }

  get types() {
    return [
      {
        value: 'stores',
        text: this.$t('reporting.types.stores'),
      },
      {
        value: 'customers',
        text: this.$t('reporting.types.customers'),
      },
    ];
  }

  get flavors(): string[] {
    return Object.values(OrderFlavor);
  }

  @Emit('change')
  public reset() {
    this.selectedFlavor = OrderFlavor.PROD_RELEASE;
    this.selectedLabel = null;
    this.selectedPeriodTypes = [];
    this.selectedCustomerGroup = '';
    this.selectedTypes = [];
    this.selectedVenues = [];

    return this.selection;
  }
}
