
import { Component } from 'vue-property-decorator';
import MetricPage from '@/components/analytics/MetricPage.vue';
import { namespace } from 'vuex-class';
import VWrapper from '@/components/shared/VWrapper.vue';
import VRangePicker from '@/components/shared/form/VRangePicker.vue';
import AnalyticsLoader from '@/components/analytics/AnalyticsLoader.vue';
import { Venue } from '@/interfaces/models/Venue';
import ExportWidget from '@/components/shared/ExportWidget.vue';
import { OrderFlavor } from '@/enums/OrderFlovor';
import Notification from '@/mixins/Notification';
import { mixins } from 'vue-class-component';
import { Download } from '@/interfaces/models/Download';
import ReportingFilter from '@/components/reporting/ReportingFilter.vue';
import { VenueLabel } from '@/interfaces/models/VenueLabel';
import { Permission } from '@/enums/Permission';
import OrderServiceApi from '@/api/http/OrderServiceApi';
import { AxiosResponse } from 'axios';
import Filter from '@/interfaces/api/Filter';

const app = namespace('app');
const label = namespace('venueLabel');
const download = namespace('download');

interface ExportFormData {
  venue: string;
  flavor: OrderFlavor;
  from: string;
  to: string;
}

@Component({
  components: { ReportingFilter, ExportWidget, AnalyticsLoader, VRangePicker, MetricPage, VWrapper },
})
export default class Reporting extends mixins(Notification) {
  @app.State('venues') public venues!: Venue[];
  @label.State('items') public labels!: VenueLabel[];
  @label.Action('fetch') public getVenueLabels!: any;

  @download.Action('addDownload') public addDownload!: (d: Download) => void;

  public filter: Filter | null = null;

  public async mounted(): Promise<void> {
    if (this.$can(Permission.LABEL_VIEW)) {
      await this.getVenueLabels();
    }
  }

  public onChange(filter: Filter) {
    this.filter = filter;
  }

  public exportReport() {
    const api: OrderServiceApi = new OrderServiceApi();
    api.exportReport(this.filter as Filter).then((res: AxiosResponse<{ file: string; contentType: string }>) => {
      this.addDownload({
        contentType: res.data.contentType,
        file: res.data.file,
        timeout: 60,
        handler: 'order',
        inProgress: false,
        done: false,
      });
      this.notifyInfo('notification.downloadInProgress');
    });
  }
}
